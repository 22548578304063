{
  var _sentryModuleMetadataGlobal =
    typeof window !== "undefined"
      ? window
      : typeof global !== "undefined"
      ? global
      : typeof self !== "undefined"
      ? self
      : {};

  _sentryModuleMetadataGlobal._sentryModuleMetadata =
    _sentryModuleMetadataGlobal._sentryModuleMetadata || {};

  _sentryModuleMetadataGlobal._sentryModuleMetadata[new _sentryModuleMetadataGlobal.Error().stack] =
    Object.assign(
      {},
      _sentryModuleMetadataGlobal._sentryModuleMetadata[new _sentryModuleMetadataGlobal.Error().stack],
      {"_sentryBundlerPluginAppKey:roh-js-app":true}
    );
}